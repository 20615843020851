import React, { useState, useEffect, useContext } from "react"

import SiteContext from "../context/SiteContext"

import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Header } from "../components/slices"

import { Text } from "../components/slices"

const axios = require("axios")

const Container = styled.div`
  background-color: #eeede9;
  padding-bottom: 0.1rem;
`

export default props => {
  let [data, setData] = useState(props.data.craft.entries[0])

  let context = useContext(SiteContext)

  useEffect(() => {
    context.changeColor("#272727")
  }, [])

  useEffect(() => {
    let hashes = window.location.search
      .slice(window.location.search.indexOf("?") + 1)
      .split("&")
    let params = {}
    hashes.map(hash => {
      let [key, val] = hash.split("=")
      params[key] = decodeURIComponent(val)
    })

    if (params.token) {
      axios({
        url: "https://hbfff.frb.io/api",
        method: "post",
        params: {
          token: `${params.token}`,
          "x-craft-live-preview": params["x-craft-live-preview"],
        },
        data: {
          query: `
            query {
              entries(section: ["Biography"]) {
                ... on biography_biography_Entry {
                  uri
                  title
                  slug
                  text
                  header {
                    ... on header_media_BlockType {
                      image {
                        url
                      }
                      video {
                        url
                      }
                    }
                  }
                }
              }
            }
            `,
        },
        headers: {
          Authorization: `Bearer EQgYRtC5gHijqoBTQ-uQRmykBleFdtDe`,
        },
      }).then(result => {
        setData(result.data.data.entries[0])
      })
    }
  }, [])

  return (
    <Layout>
      <SEO title="About" />
      <Container>
        <Header data={data.header[0]} />
        <Text data={data} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    craft {
      entries(section: ["Biography"]) {
        ... on Craft_biography_biography_Entry {
          uri
          title
          slug
          text
          header {
            ... on Craft_header_media_BlockType {
              image {
                url
                ... on Craft_images_Asset {
                  url
                  imageFile {
                    childImageSharp {
                      fluid(quality: 100, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
              video {
                url
              }
            }
          }
        }
      }
    }
  }
`
